import React from 'react';
import {
    ContentContainer,
    Paragraph,
    Heading,
    UnorderedList,
    ListItem,
    SubHeading
} from './ContentGuidanceSMSContentComponent.styled';

const ContentGuidanceSMSContentComponent = (props) => {

    const anchorMenuMetaData = props.menuMetaData;

    return (
        <ContentContainer>
            <div>
                <Paragraph>
                    These guidelines demonstrate best practice and will help you create simple, clear and understandable
                    SMS content.</Paragraph>
                <Paragraph>
                    They are for anyone designing SMS messages, particularly those writing the message
                    content.</Paragraph>
            </div>
            <div>
                <img width='270' src={ `${process.env.PUBLIC_URL}/assets/images/sms-content-sample.png` }
                    alt='sms-content-sample'/>
            </div>
            <div id={ `${anchorMenuMetaData.data[1].id}` }><Heading>Overview</Heading></div>
            <div>
                <Paragraph>SMS (text messages) are short messages sent directly to a customer’s mobile device. SMS
                    should only be used as a complementary communication channel. Ensure customers also receive the
                    information contained in any SMS through another channel, such as inbox or email.
                </Paragraph>
                <Paragraph>An SMS can help customers:</Paragraph>
                <UnorderedList>
                    <ListItem>stay up to date on the latest information</ListItem>
                    <ListItem>remember important dates</ListItem>
                    <ListItem>learn about new support opportunities.</ListItem>
                </UnorderedList>
            </div>
            <div><SubHeading>Privacy guidance</SubHeading></div>
            <div>
                <Paragraph>Before you send an SMS, you must get a customer’s consent. We do this by asking them to
                    either opt-out or opt-in to receive information via SMS.
                </Paragraph>
                <Paragraph>If SMS is a new product offering in your team, ensure your privacy policy states how you
                    handle a customer’s personal or business information. Your Privacy Collection Notice may also need
                    to be updated. Speak with your Privacy team for advice.</Paragraph>
            </div>
            <div id={ `${anchorMenuMetaData.data[2].id}` }><Heading>SMS templates</Heading></div>
            <div>
                <Paragraph>We have 2 types of SMS templates:</Paragraph>
                <UnorderedList>
                    <ListItem>short</ListItem>
                    <ListItem>long</ListItem>
                </UnorderedList>
            </div>
            <div><SubHeading>Short</SubHeading></div>
            <div>
                <Paragraph>Our short SMS template is limited to 160 characters, including spaces.</Paragraph>
                <Paragraph>This SMS template:</Paragraph>
                <UnorderedList>
                    <ListItem>informs the customer they have received a notification in their account</ListItem>
                    <ListItem>provides the customer with a link to their account</ListItem>
                </UnorderedList>
                <Paragraph>A short SMS doesn’t provide any specific customer information.</Paragraph>
            </div>
            <div><SubHeading>Example of a short SMS</SubHeading></div>
            <div>
                <img width='400' src={ `${process.env.PUBLIC_URL}/assets/images/sms-short-example.png` }
                    alt='sms-short-example'/>
            </div>
            <div><SubHeading>Long</SubHeading></div>
            <div>
                <Paragraph>Our long SMS template is limited to 320 characters, including spaces.</Paragraph>
                <Paragraph>This template is only for licence notifications as it uses specific data fields. You can use
                    it as a reference for your long SMS notification.</Paragraph>
                <Paragraph>This SMS template:</Paragraph>
                <UnorderedList>
                    <ListItem>addresses the customer by their first name</ListItem>
                    <ListItem>informs the customer they have received a notification in their account</ListItem>
                    <ListItem>provides some detail on the context of the information</ListItem>
                    <ListItem>gives customers a link to their account</ListItem>
                    <ListItem>includes an opt-out link</ListItem>
                </UnorderedList>
                <Paragraph>Provide enough detail for the customer to understand the context of the notification and take
                    action.</Paragraph>
                <Paragraph>You can exceed the 320-character limit if it aligns with your business needs. However, this
                    could be an indication that SMS is not the best fit for your message and it may be worth
                    reconsidering the channel you use to communicate. For example, an email might be a more appropriate
                    channel as there are fewer character restrictions.</Paragraph>
            </div>
            <div><SubHeading>Character limitations</SubHeading></div>
            <div>
                <Paragraph>When you send an SMS over 160 characters (including white spaces), the SMS
                    splits.</Paragraph>
                <Paragraph>It's divided into 153-character segments and sent one by one. It’s then rebuilt by the
                    customer’s device to appear as one SMS.</Paragraph>
                <Paragraph>For example, a 161-character SMS is sent as 2 SMS, one with 153 characters and the second
                    with 8 characters. However, the customer only sees one SMS.</Paragraph>
            </div>
            <div><SubHeading>Example of a long SMS</SubHeading></div>
            <div>
                <img width='400' src={ `${process.env.PUBLIC_URL}/assets/images/sms-long-example.png` }
                    alt='sms-long-example'/>
            </div>
            <div id={ `${anchorMenuMetaData.data[3].id}` }><Heading>Writing guidance</Heading></div>
            <div>
                <Paragraph>Always ensure your SMS is easy to read and appears trustworthy by breaking up sentences and
                    links with spaces.</Paragraph>
                <Paragraph>Never include any personal, private or sensitive information in an SMS to a customer,
                    including:</Paragraph>
                <UnorderedList>
                    <ListItem>email address</ListItem>
                    <ListItem>details of another person</ListItem>
                </UnorderedList>
            </div>
            <div><SubHeading>Anatomy of components</SubHeading></div>
            <div>
                <Paragraph>Each type of SMS template uses a combination of components.</Paragraph>
            </div>
            <div><SubHeading>1. Greeting</SubHeading></div>
            <div>
                <Paragraph>Address your customers with ‘Hello [firstname],’</Paragraph>
                <Paragraph>This language reflects our everyday tone. It’s not formal, like ‘Dear’ or too casual, like
                    ‘Hi' or 'Hey’.</Paragraph>
            </div>
            <div><SubHeading>2. Introduction</SubHeading></div>
            <div>
                <Paragraph>Immediately tell the customer what they need to know.</Paragraph>
                <Paragraph>Don’t use emotive language such as: ‘We're pleased to inform you’.</Paragraph>
                <Paragraph>Instead, get straight to the point and use clear, direct language such as: ‘A licence saved
                    to your Business Profile has expired on [date].’</Paragraph>
            </div>
            <div><SubHeading>3. Body</SubHeading></div>
            <div>
                <Paragraph>Encourages the customer’s next action.</Paragraph>
                <Paragraph>Don’t add a new line (space) between the greeting and body content. For example: ‘For more
                    information, go to’.</Paragraph>
            </div>
            <div><SubHeading>4. Data fields (long SMS only)</SubHeading></div>
            <div>
                <Paragraph>A data field is a placeholder that is substituted with information tailored to the customer.
                    It creates a personalised SMS.</Paragraph>
                <Paragraph>Information within a data field could include:</Paragraph>
                <UnorderedList>
                    <ListItem>first name</ListItem>
                    <ListItem>date</ListItem>
                    <ListItem>name of service or program</ListItem>
                    <ListItem>status of service or program</ListItem>
                    <ListItem>required action</ListItem>
                    <ListItem>renew</ListItem>
                    <ListItem>apply</ListItem>
                    <ListItem>claim</ListItem>
                </UnorderedList>
                <Paragraph>Data fields begin with:</Paragraph>
                <UnorderedList>
                    <ListItem>a unique symbol like $</ListItem>
                    <ListItem>a descriptive name to indicate the type of data that will be substituted into the
                        field</ListItem>
                </UnorderedList>
                <Paragraph>For example: ‘Hello $firstName’ will be substituted into ‘Hello Michael’.</Paragraph>
                <Paragraph>If a data field does not contain matching information to be substituted, the data field will
                    not be replaced. This enables the unique symbol to be used for other applications alongside data
                    fields.</Paragraph>
            </div>
            <div><SubHeading>5. Call to action and linking</SubHeading></div>
            <div>
                <Paragraph>Provide direct links. This allows customers to navigate directly to the source of the
                    information.</Paragraph>
                <Paragraph>Don’t insert multiple links close to each other. This makes it hard for the customer to
                    select the link and increases their cognitive load.</Paragraph>
                <Paragraph>Cyber Security NSW recommends using the full URL, instead of hyperlinked words or shortened
                    hyperlinks. For example, say https://www.service.nsw.gov.au/ instead of    &lt;Service
                    NSW&gt;.</Paragraph>
                <Paragraph>Shortened links are a feature of phishing campaigns. It’s important to provide customers with
                    the same URL as the destination website to create trust and transparency.</Paragraph>
            </div>
            <div><SubHeading>6. Opt-out link</SubHeading></div>
            <div>
                <Paragraph>You can give customers the choice of opting out of SMS notifications by including an opt-out
                    link in your message.</Paragraph>
                <Paragraph>An opt-out link will increase the character count of your notification, so if you do include
                    one you’ll need to select the long SMS template.</Paragraph>
            </div>
            <div><SubHeading>7. Sign off</SubHeading></div>
            <div>
                <Paragraph>Tells the customer who the SMS is from and ends the SMS.</Paragraph>
                <Paragraph>Always sign off with either:</Paragraph>
                <UnorderedList>
                    <ListItem>Thanks,<br/>Service NSW</ListItem>
                    <ListItem>Thanks,<br/>Service NSW Business Bureau</ListItem>
                </UnorderedList>
                <Paragraph>Customers receive multiple SMS, so it’s important we close our SMS with the same sign-off.
                    Consistency in messaging and tone helps to build trust with customers. It also tells customers the
                    SMS is not a scam.</Paragraph>
            </div>
            <div id={ `${anchorMenuMetaData.data[4].id}` }><Heading>Dos and don'ts</Heading></div>
            <div>
                <Paragraph>Do:</Paragraph>
                <UnorderedList>
                    <ListItem>Ensure information contained in any SMS is also received by customers through another
                        channel</ListItem>
                    <ListItem>Get customers’ consent before sending an SMS</ListItem>
                    <ListItem>Use consistent messaging and tone to build trust with customers</ListItem>
                </UnorderedList>
                <Paragraph>Don’t:</Paragraph>
                <UnorderedList>
                    <ListItem>Use shortened URLs</ListItem>
                    <ListItem>Use emotive language</ListItem>
                    <ListItem>Include non-essential information</ListItem>
                    <ListItem>Include private or sensitive information</ListItem>
                </UnorderedList>
            </div>
        </ContentContainer>
    );
};

export default ContentGuidanceSMSContentComponent;
