import React from 'react';
import {BodyRegular, colors, Heading3} from '../Typography/Typography.styled';
import {Divider} from '@mui/material';
import {InPageAlert} from '@snsw/react-component-library';

const CustomerCountComponent = ({noOfCustomers, errorMessage}) => {
    if (noOfCustomers === undefined || noOfCustomers === null || isNaN(noOfCustomers)) {
        return (
            <><Divider color={ colors.grey.geyser } width={ '100%' } style={ {marginTop: '20px'} }/>
                <InPageAlert data-testid='customer-count-error'
                    title='Estimation of customers is not available' variant='error'>
                    {errorMessage && <p>{errorMessage}</p>}
                </InPageAlert>
            </>
        );
    }

    const formattedNoOfCustomers = noOfCustomers?.toLocaleString();

    return <>
        <Divider color={ colors.grey.geyser } width={ '100%' } style={ {marginTop: '20px'} }/>
        <Heading3 data-testid='customer-count' id={ 'customer-count' }
            style={ {marginTop: '20px', color: `${colors.grey.darkGrey}`} }>
            {formattedNoOfCustomers}
        </Heading3>
        <BodyRegular data-testid='customer-count-info' style={ {marginTop: '6px'} }>Estimated number of
            customers</BodyRegular>
    </>;
};

export default CustomerCountComponent;