// SingleSelect.js
import React from 'react';
import Select from 'react-select';
import {SelectLabel, SelectSpanError} from './SelectComponent.styled';
import {boxShadowStyle, colors, fontSize, globalFont} from '../Typography/Typography.styled';

export const getBaseControlStyles = (state) => ({
    fontSize: `${fontSize.body}`,
    fontFamily: `${globalFont}`,
    border: `1.5px solid ${colors.grey.geyser}`,
    boxShadow: state.isFocused ? `${boxShadowStyle}` : 'none',
    borderRadius: '4px'
});

export const getOptionStyles = (state) => ({
    fontSize: `${fontSize.body}`,
    fontFamily: `${globalFont}`,
    alignItems: 'center',
    cursor: 'pointer',
    backgroundColor: state.isSelected ? `${colors.grey.iceBlue}` : (state.isFocused ? `${colors.grey.neutral}` : `${colors.white}`),
    color: state.isSelected ? `${colors.brand.snswPrimaryBlue}` :
        (state.isDisabled ? `${colors.status.disabled}` : `${colors.grey.darkGrey}`),
    '&:hover': {
        backgroundColor: state.isSelected ? `${colors.grey.iceBlue}` : `${colors.grey.neutral}`
    }
});

export const customTheme = (theme) => ({
    ...theme,
    colors: {
        ...theme.colors,
        primary: `${colors.brand.snswPrimaryBlue}`,
        primary75: `${colors.brand.snswSecondaryBlue}`,
        primary50: `${colors.brand.snswTertiaryBlue}`,
        primary25: `${colors.grey.iceBlue}`,
        neutral50: `${colors.grey.shuttle}`
    }
});

const SingleSelectComponent = ({
    options, value, onChange, placeholder, label, hideLabel,
    name, hasError, errorMessage, defaultValue, isDisabled = false,
    isSearchable = true
}) => {
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            ...getBaseControlStyles(state),
            borderColor: hasError ? `${colors.status.errorRed.default}` :
                (state.isFocused ? `${colors.grey.neutral200}` : `${colors.grey.geyser}`),
            height: '40px'
        }),
        option: (provided, state) => ({
            ...provided,
            ...getOptionStyles(state)
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: `${colors.white}`,
            boxShadow: `${boxShadowStyle}`,
            borderRadius: '6px',
            border: `1px solid ${colors.grey.geyser}`,
            zIndex: 2,
            position: 'absolute'
        })
    };

    const labelId = `single-select-${name}-label`;
    const selectId = `single-select-${name}`;

    return (
        <>
            {label && (
                <SelectLabel style={ {display: hideLabel ? 'none' : 'block'} } id={ labelId } htmlFor={ selectId }>
                    {label}
                </SelectLabel>
            )}
            <Select
                inputId={ selectId }
                id={ selectId }
                aria-labelledby={ labelId }
                options={ options }
                value={ value }
                onChange={ onChange }
                components={ {
                    ClearIndicator: null,
                    IndicatorSeparator: null
                } }
                placeholder={ placeholder || 'Select an option' }
                styles={ customStyles }
                theme={ customTheme }
                defaultValue={ defaultValue || null }
                isDisabled={ isDisabled }
                isSearchable={ isSearchable }
            />

            {hasError && (<SelectSpanError id='multi-select-error'>{errorMessage}</SelectSpanError>)}
        </>
    );
};

export default SingleSelectComponent;
