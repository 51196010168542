import React from 'react';
import AnchorMenuComponent from '../CommonComponents/AnchorMenuComponent/AnchorMenuComponent';
import AnchorMenuLayoutComponent from '../CommonComponents/AnchorMenuLayoutComponent/AnchorMenuLayoutComponent';
import ContentGuidanceSMSContentComponent from './ContentGuidanceSMSContentComponent';

const ContentGuidanceSMSComponent = () => {

    const anchorMenuMetaData = {
        title: 'On this page',
        data: [
            {id: 'menu-sms-notifications', title: 'SMS notifications', level: 0},
            {id: 'menu-overview', title: 'Overview', level: 1},
            {id: 'menu-sms-templates', title: 'SMS templates', level: 1},
            {id: 'menu-writing-guidance', title: 'Writing guidance', level: 1},
            {id: 'menu-dos-donts', title: 'Dos and don\'ts', level: 1}
        ]
    };

    return (
        <AnchorMenuLayoutComponent
            title='SMS notifications'
            contentComponent={ ContentGuidanceSMSContentComponent }
            anchorMenuComponent={ AnchorMenuComponent }
            anchorMenuMetaData={ anchorMenuMetaData }
        />
    );
};

export default ContentGuidanceSMSComponent;
