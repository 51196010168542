import styled from 'styled-components';
import {
    globalFont,
    fontWeight,
    fontSize, lineHeight, Heading2
} from '../CommonComponents/Typography/Typography.styled';

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    font-family: ${globalFont};
    font-size: ${fontSize.body};
    line-height: ${lineHeight.body};
    font-weight: ${fontWeight.regular};
    padding-bottom: 56px;
`;

export const Paragraph = styled.p`
    margin: 0px;
    + p {
        margin-top: 10px;
    }
`;

export const Heading = styled(Heading2)`
    font-size: ${fontSize.h5};
    line-height: ${lineHeight.h5};
    font-weight: ${fontWeight.bold};
    font-family: ${globalFont};
`;

export const CardContainer = styled.div`
    display: flex;
    width: 100%;
    gap: 20px;
    flex-wrap: wrap;

    > div {
        flex: 0 0 335px;
    }
`;