import React from 'react';
import {CardContainer, ContentContainer, Heading, Paragraph} from './ContentGuidanceContentComponent.styled';
import {Card, CardTitle} from '@snsw/react-component-library/build/Components';
import {Link} from 'react-router-dom';

const ContentGuidanceContentComponent = () => {

    return (
        <ContentContainer>
            <div>
                <Paragraph>
                    Content design is the practice of designing content that best meets the customer’s needs.
                    It uses data and evidence to give the user what they need, at the time they need it, and in a way
                    they expect.
                </Paragraph>
            </div>
            <div><Heading>Digital notifications and content design</Heading></div>
            <div>
                <Paragraph>
                    Digital Notifications Content Designers work collaboratively with other Digital Notification
                    squad members such as Product Designers, Product Managers and Engineers, to create accessible
                    and effective content for a range of customers.
                </Paragraph>
                <Paragraph>
                    They align with Service NSW and NSW Government best-practice guidelines.
                </Paragraph>
            </div>
            <div><Heading>Quick start guide</Heading></div>
            <div>
                <Paragraph>Start here to learn the basics of content design.</Paragraph>
            </div>
            <div>
                <CardContainer>
                    <div>
                        <Card isClickable>
                            <CardTitle level='4'>
                                <Link to='/content-guidance/push'>Push notifications</Link>
                            </CardTitle>
                            <div style={ {width: '287'} }>
                                <p>A push notification is a message sent through our app. </p>
                            </div>
                        </Card>
                    </div>
                    <div>
                        <Card isClickable>
                            <CardTitle level='4'>
                                <Link to='/content-guidance/email'>Emails</Link>
                            </CardTitle>
                            <div style={ {width: '287'} }>
                                <p>Learn best practice and how to create simple, clear and understandable emails.</p>
                            </div>
                        </Card>
                    </div>
                    <div>
                        <Card isClickable>
                            <CardTitle level='4'>
                                <Link to='/content-guidance/sms'>SMS notifications</Link>
                            </CardTitle>
                            <div style={ {width: '287'} }>
                                <p>An SMS is a short message sent directly to a customer’s mobile device.</p>
                            </div>
                        </Card>
                    </div>
                </CardContainer>
            </div>
        </ContentContainer>
    );
};

export default ContentGuidanceContentComponent;
